import { useEffect, useRef } from 'react';

import gsap from 'gsap/dist/gsap';
import SplitText from 'gsap/dist/SplitText';
import { useTranslation } from 'next-i18next';

import { Button } from 'common/components/Button';

gsap.registerPlugin(SplitText);

export const HeroHomepage: React.FC = () => {
  const { t } = useTranslation(['common', 'pageHomepage']);
  const el = useRef(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const q = gsap.utils.selector(el);
    gsap
      .timeline({ delay: 1.8 })
      .to(q('#hero-text'), { duration: 1, opacity: 1, transform: 'none' });
    gsap
      .timeline({ delay: 2 })
      .to(q('#hero-button-one'), { duration: 1, opacity: 1, transform: 'none' });
    gsap
      .timeline({ delay: 2.2 })
      .to(q('#hero-button-two'), { duration: 1, opacity: 1, transform: 'none' });
  }, []);

  useEffect(() => {
    const tl = gsap.timeline();
    const mySplitText = new SplitText('#hero-title', { type: 'chars,words' });
    const chars = mySplitText.chars;
    gsap.set('#hero-title', { perspective: 400 });
    tl.from(chars, {
      delay: 0.8,
      duration: 1,
      opacity: 0,
      scale: 0,
      y: 80,
      rotationX: 90,
      transformOrigin: '0% 50% -50',
      ease: 'back',
    });
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5;
      videoRef.current.play();
    }
  }, [videoRef.current]);

  return (
    <section
      ref={el}
      className="bg-gradient top-0 flex min-h-[100vh] w-[100vw] flex-col items-center justify-center overflow-hidden pb-6 pt-48 md:sticky md:pt-32 lg:pb-10 lg:pt-32">
      <video
        ref={videoRef}
        className="absolute bottom-0 right-0 h-full w-[100vw] object-cover"
        loop
        autoPlay
        muted
        playsInline>
        <source
          src="https://firebasestorage.googleapis.com/v0/b/nordit-83a0b.appspot.com/o/heroVideo.mp4?alt=media&token=5544cf62-e98e-4a23-8ef3-99ccee509131"
          type="video/mp4"
        />
      </video>
      <div className="bg-gradient absolute bottom-0 left-0 right-0 top-0 opacity-[0.96]" />
      <div className="container">
        <h1
          id="hero-title"
          className="text-heading-hero"
          dangerouslySetInnerHTML={{ __html: t('pageHomepage:hero.title') }}
        />

        <div
          id="hero-text"
          className="my-10 max-w-xl translate-y-4 text-white opacity-0"
          dangerouslySetInnerHTML={{ __html: t('pageHomepage:hero.description') }}
        />

        <div className="flex flex-col lg:flex-row">
          <Button
            id="hero-button-one"
            className="mb-2 mr-2 w-[280px] translate-y-6 opacity-0"
            href={t('common:servicesSlug')}>
            {t('whatWeDo')}
          </Button>

          <Button
            id="hero-button-two"
            className="mb-2 w-[280px] translate-y-6  opacity-0 "
            href={t('common:contactSlug')}
            type="outline">
            {t('workWithUs')}
          </Button>
        </div>
      </div>
    </section>
  );
};
